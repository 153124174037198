/* eslint-disable no-console,no-undef, camelcase, no-constant-condition */
import React, { Component } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import _ from "lodash";
// import Datetime from "react-datetime";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Waiting from "../Waiting";
import {
  getOrientation,
  resetOrientation,
  checkImage,
  getPermissionStatus,
  fetchApi,
} from "../../utils/methods";
import { recurringTypes, eventsTierPoints } from "../../../mockData";
import { getAllCompanies } from "../../redux/actions/challengeActions";
import {
  getUserCompanyDetails,
  allowChallengeEventForSpouseDependent,
} from "../../redux/actions/userProfileActions";
import { ImageUrl, imageErrorMessage } from "../../utils/constants";
import timezones from "../../../timezones.json";
import { withTranslation } from "react-i18next";
import {
  Layout,
  ChallengeHeading,
  Container,
  InputContainer,
  StyledInputV2,
  StyledLabel,
  UploadPhotoContainer,
  InputImage,
  ButtonContainer,
  CustomRadioButton,
  StyledLabelV2,
  Locationwrapper,
  Heading,
  EditorContainer,
  SearchDropDown,
  RemoveImageV2,
  CircleNew,
  MUICalendarWrapper,
} from "./style";
import StepsHeading from "./stepNames";
import RadioButton from "../CustomRadioButton";
import { TitleContainer } from "../AdminTeamCreation/styles";
import { Main } from "../AdminTeamCreation/styles";
import { getCompanyDepartment, getCompanyLocation } from "../../redux/actions";
import Editor from "../CKEditor";
import Select from "react-select";
import HoverButton from "../common/HoverButton";
import CommonDropdown from "../common/CommonDropDown/CommonDropdown";
import CommonButton from "../common/CommonButton/CommonButton";
import { Arrows, CalendarIcon, LaunchEventIcon, NextArrows, SearchButtonIcon, UploadIcon, closeIcon } from "../../utils/icons";
import CircularProgressBar from "./circularProgressBar";
import { CreateEventAPI, GetCoaches, GetCompanyInfo, GetOndemandEventDetailsById, GetTeams } from "../../redux/constants/apiConstants";
import { http } from "../../redux/http";
import { Border } from "../Journeys/styles";
import { SearchFilter1, SearchIcon } from "../PeopleHomeV2/styles";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { withRouter } from 'react-router-dom';



class CreateLunchLearnEvent extends Component {
  constructor() {
    super();
    this.state = {
      imgSrc: "",
      title: "",
      date: dayjs(),
      endDate: null,
      location: "",
      description: "",
      imageName: "",
      time: dayjs().format("hh:mm a"),
      endTime: dayjs().format("hh:mm a"),
      showDateTimeInput: "",
      selectedMenu: "Select an option",
      showPlaces: true,
      amigosToInvite: [],
      openDateTimePicker: "",
      companies: [],
      city_location: [],
      departments:[],
      teams:[],
      eventPointValue: null,
      limitUser: "1",
      addLimitCheckbox: false,
      allDayEvent: 0,
      eventPoints: [],
      isSpouse: false,
      isDependent: false,
      isEmployee: false,
      selectedOption: 0,
      eventLink: "",
      emailOption: {
        launch_event_email: 0,
        event_reminder_email: 0,
        event_complete_email: 1,
      },
      eventPointKey: "Tier 1: 0 points",
      time_zone: null,
      companiesPermission: false,
      locationPermission: false,
      recurringEvent: recurringTypes[0],
      eventLimitValue: 2,
      buttonStatus: false,
      step: 1,
      selectCategory: "",
      so: false,
      radio: null,
      selectLocation: 0,
      selectFlayer: 1,
      selectedCompanyValue: "",
      selectedCompanyCheckBox: 0,
      dropdown: false,
      flayerName: "",
      flayerValue: "",
      showBack: false,
      buttonClickScrollTop: false,
      timeout: false,
      progress: 0,
      menuIsOpen: false,
      iseventUrl: false,
      iseventUrlMsg: "",
      usersToInvite:0,
      allTeams:[],
      filteredCompanies:[],
      searchTerm: '',
      defaultTz:"",
      launch:0,
      allCoaches:[],
      coachName:"",
      coachSo:false,
      isOnDemandEvent:false,
      eventId:null,
      loadingOnDemandEvent:false,
      coachId:null,
      videoUrl:null,
      showFrequency: true,
    };
  }

  componentDidMount() {

    if(this.props.history?.location?.state?.isOnDemandEvent){
      this.setState({isOnDemandEvent:true,eventId:this.props.history?.location?.state?.eventId});
      this.GetOndemandEvent(this.props.history?.location?.state?.eventId);
    }

    const timezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.setState({time_zone:timezoneName === "Asia/Calcutta" ? "Asia/Kolkata" : timezoneName,defaultTz:timezoneName === "Asia/Calcutta" ? "Asia/Kolkata" : timezoneName })

    console.log(timezoneName);
    const companiesPermission = getPermissionStatus(
      "Show companies in event",
      this.props.userPermissions
    );
    const locationPermission = getPermissionStatus(
      "Show locations in event",
      this.props.userPermissions
    );
    if (companiesPermission) {
      if (!this.props.allCompanies) {
        this.props.fetchAllCompanies();
      }else{
        this.setState({filteredCompanies:this.props.allCompanies})
        
      }
    } else {
      this.props.fetchCompanyForUser();
      this.props.allowChallengeEventForSpouseDependent();
      this.setState({
        companies: this.props.userCompany && [this.props.userCompany.id],
      });
    }
    document.addEventListener("mousedown", this.handleClick);
    if (this.props.role === "WELLNESS_CHAMPION") {
      let arr = eventsTierPoints;
      arr.splice(3);
      this.setState({ eventPoints: arr });
    } else {
      this.setState({ eventPoints: eventsTierPoints });
    }
    this.setState({
      companiesPermission: companiesPermission,
      locationPermission: locationPermission
    });
    const{getCompanyDepartment}=this.props;
    getCompanyDepartment(this.props.companyDetails.id);
    this.getTeams(this.props.companyDetails.id);

    this.getCoaches();
    this.getCompanyInfo(this.props.companyDetails.id);
  }

  getCompanyInfo = async(companyId)=>{
    let apiUrl = GetCompanyInfo + "/" + companyId;
    try {
      const res = await fetchApi(apiUrl , "GET");
      if (res.status === "success") {
        this.setState({companyInfo: res?.data?.companyInfo?.[0]})

      } else {
        toast.error(res.error);
      }
    } catch (error) {
      window.console.log(error);
    }

  }

  GetOndemandEvent = async (eventId) => {
    this.setState({loadingOnDemandEvent:true})
    const apiUrl = GetOndemandEventDetailsById + (eventId && "/" + eventId);
    try {
      const res = await fetchApi(apiUrl, "GET");
      if (res.status === "success") {
        this.setState({loadingOnDemandEvent:false,title:res?.data[0]?.title,description:res.data[0].description,imgSrc:ImageUrl + "/" + res.data[0].thumbnail_image,eventLink:res.data[0].video_url,imageUrl:res.data[0].thumbnail_image,iseventUrl:true,videoUrl:res.data[0].video_url,showFrequency:true})
        window.console.log("res",res)

      } else {
        toast.error(res.error);
        this.setState({loadingOnDemandEvent:false})
      }
    } catch (error) {
      window.console.log(error);
    }
  };

  componentDidUpdate(prevProps) {
    this.state.flayerName &&
      this.state.progress < 100 &&
      setTimeout(this.progress, 1000);
    if (this.state.buttonClickScrollTop) {
      window.scrollTo(0, 0);
      window.setTimeout(() => {
        this.setState({ buttonClickScrollTop: false });
      }, 2000);
    }
    if (
      !this.state.companiesPermission &&
      this.props.userCompany !== prevProps.userCompany
    ) {
      this.setState({
        companies: this.props.userCompany && [this.props.userCompany.id],
      });
    }
    if(prevProps.allCompanies != this.props.allCompanies){
      this.setState({filteredCompanies:this.props.allCompanies})
    }
    
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick);
  }

  handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    this.setState({ searchTerm: value });

    const filtered = this.props.allCompanies.filter((company) =>
      company.company_name.toLowerCase().includes(value)
    );

    this.setState({ filteredCompanies: filtered });
  };


  progress = () => {
    this.setState({ progress: this.state.progress < 100 && this.state.progress + 20 });
  };

  changeLimitCheckBox = (value) => {
    window.console.log("hi")
    this.setState({
      addLimitCheckbox: value,
    });
  };

  eventButtonStatusHover = () => {
    this.setState({
      showBack: true,
    });
  };

  eventButtonStatus = () => {
    this.setState({
      showBack: false,
    });
  };

  onSelectCategory = (name, value) => {
    this.setState({
      [name]: value
    });
    if(name !== 'time_zone')
    {
      this.setState({
        eventLimitValue: name,
      });
    }
  };


  onSelectLocation = (name, /*value, id*/) => {
    window.console.log("name",name)
    this.setState({
      [name]: name.company_name,
      companies: [name.id],
      isEmployee:false,
      isDependent:false,
      isSpouse:false,
      selectedCompanyValue: name.company_name,
    });
    
    this.props.getCompanyLocation(name.id);
    this.props.getCompanyDepartment(name.id);
    this.getTeams(name.id);
    this.getCompanyInfo(name.id);
  };

  getTeams = async(companyId)=>{
    const AuthToken = localStorage.getItem('AUTH_TOKEN');
    try {
      const res = await http.get(`${GetTeams}/${companyId}`, {headers: {AUTHTOKEN: AuthToken}});
      if (res.message) {
        toast.error(res.message);
      }else{
        this.setState({
          allTeams:res
        })
      }
    }catch(error){
      toast.error(error.message);
    }
  }

  getCoaches = async(categoryId)=>{

    let apiUrl = GetCoaches + (categoryId ?  "/" + categoryId : "");

    window.console.log("apiUrl",apiUrl)
    
    try{
      const res = await fetchApi(apiUrl, "GET");
      if(res.message){
        toast.error(res.message);
      }else{
        let coaches = res.data;
        coaches.unshift({id:null,name:"None"}); 
        this.setState({allCoaches: coaches });
      }

    }catch(error){
      toast.error(error)

    }
  }


  onChangeLocation = (id) => {
    const { city_location } = this.state;
    if (city_location.includes(id)) {
      let index = city_location.findIndex((item) => item === id);
      if (index > -1) {
        city_location.splice(index, 1);
      }
    } else {
      city_location.push(id);
    }
    this.setState({
      city_location: city_location,
    });
  };

  onChangeDepartment = (id) => {
    const { departments } = this.state;
    if (departments.includes(id)) {
      let index = departments.findIndex((item) => item === id);
      if (index > -1) {
        departments.splice(index, 1);
      }
    } else {
      departments.push(id);
    }
    this.setState({
      departments: departments,
    });
  };

  onChangeTeam = (id) => {
    const { teams } = this.state;
    if (teams.includes(id)) {
      let index = teams.findIndex((item) => item === id);
      if (index > -1) {
        teams.splice(index, 1);
      }
    } else {
      teams.push(id);
    }
    this.setState({
      teams: teams,
    });
  };

  selectAllDepartment = (e, locationDetails) => {
    e.preventDefault();
    const { departments } = this.state;
    if (departments.length == locationDetails?.length) {
      this.setState({
        departments: [],
      });
    } else {
      let arr = [];
      for (let i = 0; i < locationDetails?.length; i++) {
        arr.push(locationDetails[i].id);
      }
      this.setState({
        departments: arr,
      });
    }
  };

  selectAllTeam = (e, teamDetails)=>{
    e.preventDefault();
    const { teams } = this.state;
    if (teams.length == teamDetails.length) {
      this.setState({
        teams: [],
      });
    } else {
      let arr = [];
      for (let i = 0; i < teamDetails.length; i++) {
        arr.push(teamDetails[i].id);
      }
      this.setState({
        teams: arr,
      });
    }

  }

  selectAllLocation = (e, locationDetails) => {
    e.preventDefault();
    const { city_location } = this.state;
    if (city_location.length == locationDetails?.length) {
      this.setState({
        city_location: [],
      });
    } else {
      let arr = [];
      for (let i = 0; i < locationDetails?.length; i++) {
        arr.push(locationDetails[i].id);
      }
      this.setState({
        city_location: arr,
      });
    }
  };

  handleClick = (e) => {
    if (
      typeof this.datePickerWrapper === "undefined" &&
      this.datePickerWrapper === null
    ) {
      return;
    }
    if (
      !_.isNull(this.datePickerWrapper) &&
      !_.isUndefined(this.datePickerWrapper) &&
      this.datePickerWrapper &&
      !this.datePickerWrapper.contains(e.target)
    ) {
      this.setState({
        openDateTimePicker: "",
      });
    }
  };


  onChange = (e) => {
    let file = e.target.files[0];
    let fileArr = e.target.files;
    if (e.target.files[0]) {
      if (checkImage(fileArr)) {
        if (file?.size / 1000000 <= 20) {
          let reader = new FileReader();
          reader.readAsDataURL(file);
          const array = document
            .getElementById("event-upload-file")
            .value.split("\\");
          reader.onloadend = () => {
            this.setState({
              imgSrc: reader.result,
              imageName: array[array.length - 1],
            });
            getOrientation(file, (or) => {
              resetOrientation([reader.result], or, (baseImage) => {
                this.setState({
                  imgSrc: baseImage,
                });
              });
            });
          };
        } else {
          toast.error("Please select image file less than 20MB");
          document.getElementById("event-upload-file").value = "";
        }
      } else {
        toast.error(imageErrorMessage);
        document.getElementById("event-upload-file").value = "";
      }
    }
  };

  uploadPDFFile = (e) => {
    let file = e.target.files[0];
    if (file) {
      let fileArr = file.name.split(".");
      if (
        fileArr[fileArr.length - 1].toUpperCase() == "PNG" ||
        "PDF" ||
        "JPEG" ||
        "JPG"
      ) {
        if (file?.size / 1000000 <= 10) {
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onloadend = () => {
            this.setState({
              flayerValue: reader.result,
              flayerName: file.name,
            });
          };
        } else {
          toast.error("Please select PDF file less than 10MB");
          document.getElementById("event-upload-file").value = "";
        }
      } else {
        toast.error("Please select PDF file");
        document.getElementById("event-upload-file").value = "";
      }
    } else {
      // this.changeLoader(false);
      this.setState({
        flayerValue: "",
        flayerName: "",
      });
      document.getElementById("event-upload-file").value = "";
    }
    if (this.state.flayerValue) {
      this.setState({
        timeout: true,
      });
    }
  };

  onChangeInput = (e) => {
    if (e.target.value.charAt(0) !== " ") {
      this.setState({
        [e.target.name]: e.target.value,
      });

      if (e.target.name === "eventLink") {
        this.isUrl(e.target.value);
      }
    }
  };

  onChangeTimeZone = (name, value) => {
    if (name === "recurringEvent") {
      const { emailOption } = this.state;
      if (value !== recurringTypes[0]) {
        emailOption["launch_event_email"] = 0;
      }
      if (value !== recurringTypes[1]) {
        this.setState({
          eventLimitValue: 2,
        });
      }
      this.setState({
        [name]: value,
        emailOption: emailOption,
        endDate: null,
        date: dayjs(),
        time: dayjs().format("hh:mm a"),
        endTime: dayjs().format("hh:mm a"),
      });
    } else {
      this.setState({
        [name]: value,
      });
    }
  };

  removePhoto = () => {
    this.setState({
      imgSrc: "",
      imageName: "",
    });
  };


  onSelect = (value) => {
    this.setState({
      selectedMenu: value,
    });
  };

  onSelectPoints = (name, /*value, key*/) => {
    this.setState({
      [name]: name.value,
      eventPointKey: name.key,
      eventPointValue: name.value,
    });
  };

  onSelectCoach = (name, /*value, key*/) => {
    window.console.log("name",name)
    this.setState({
      coachName: name.name,
      coachId: name.id,
    });
  };

  changeDate = (e, stateName) => {
    console.log("e",e)
    const { showDateTimeInput } = this.state;
    if (showDateTimeInput === "date") {
      this.setState({
        date: moment(e._d).format("MM/DD/YYYY"),
        time: moment(e._d).format("hh:mm a"),
        endDate: moment(e._d).format("MM/DD/YYYY"),
        endTime: "11:59 pm",
        openDateTimePicker: "",
      });
    } else if (stateName.includes("ate")) {
      this.setState({
        openDateTimePicker: "",
        [stateName]: moment(e._d).format("MM/DD/YYYY"),
      });
    } else {
      this.setState({
        [stateName]: e,
        openDateTimePicker: stateName,
      });
    }
  };

  onChangeDate = (e, stateName) => {
    console.log("e",e)
    const { showDateTimeInput } = this.state;
    if (showDateTimeInput === "date") {
      this.setState({
        date: dayjs(e),
        time: dayjs(e).format("hh:mm a"),
        endDate: dayjs(e).add(2, 'day'),
        endTime: "11:59 pm",
        openDateTimePicker: "",
      });
    } else if (stateName.includes("ate")) {
      this.setState({
        openDateTimePicker: "",
        [stateName]: dayjs(e),
      });
    } else {
      this.setState({
        [stateName]: e,
        openDateTimePicker: stateName,
      });
    }
  };

  onCheckboxChange = (element, value) => {
    const { companies } = this.state;
    if (companies.includes(element)) {
      let index = companies.findIndex((item) => item === element);
      if (index > -1) {
        companies.splice(index, 1);
      }
    } else {
      companies.push(element);
    }
    this.setState({
      companies: companies,
      selectedCompanyValue: value,
    });
  };

  onCompanyCheckboxChange = (element) => {
    const { companies } = this.state;
    if (companies.includes(element)) {
      let index = companies.findIndex((item) => item === element);
      if (index > -1) {
        companies.splice(index, 1);
      }
    } else {
      companies.push(element);
    }
    this.setState({
      companies: companies,
    });
  };

  onChangeEmail = (key) => {
    const { emailOption } = this.state;
    emailOption[key] = emailOption[key] === 0 ? 1 : 0;
    this.setState({ emailOption: emailOption });
  };

  selectAllCompanies = (e) => {
    e.preventDefault();
    let invitedCompanies = [];
    const { allCompanies } = this.props;
    if (this.state.companies.length === allCompanies.length) {
      invitedCompanies = [];
    } else {
      allCompanies.map((company) => {
        invitedCompanies.push(company.id);
      });
    }
    this.setState({
      companies: invitedCompanies,
    });
  };

  onLocationChange = (e) => {
    const { getPlacesAction } = this.props;
    if (e.target.value.charAt(0) !== " ") {
      this.setState(
        {
          location: e.target.value,
          showPlaces: true,
        },
        () => {
          if (
            this.state.location.length > 3 &&
            this.state.location.trim() != ""
          )
            getPlacesAction(this.state.location.trim());
        }
      );
    }
  };

  setLocation = (location) => {
    this.setState({
      location: location,
      showPlaces: false,
    });
  };

  renderPlaces = (places) =>
    places.map((place, index) => (
      <div key={index} onClick={() => this.setLocation(place.description)}>
        <span>{this.props.t(place.description)}</span>
      </div>
    ));

  selectSpouseDependent = (stateName) => {
    this.setState((prev) => ({
      [stateName]: !prev[stateName],
    }));
  };

  callBackForButtonstatus = () => {
    this.setState({
      buttonStatus: false,
    });
  };

  saveEvent = () => {
    // e.preventDefault();
    const {
      city_location,
      title,
      date,
      description,
      imgSrc,
      // time,
      endDate,
      // endTime,
      location,
      eventLink,
      companies,
      eventPointValue,
      addLimitCheckbox,
      limitUser,
      allDayEvent,
      selectedOption,
      time_zone,
      emailOption,
      locationPermission,
      recurringEvent,
      eventLimitValue,
      flayerValue,
      selectedCompanyCheckBox,
      usersToInvite,
      departments,
      teams,
      isOnDemandEvent,
      imageUrl
    } = this.state;
    const { /*createEvent,*/ companyDetails, role } = this.props;
    this.setState({
      buttonStatus: true,
    });
    let isRecurringEvent = recurringEvent === recurringTypes[0] ? 0 : 1;
    window.console.log("isRecurringEvent",isRecurringEvent)
    let EventDetails = {
      title,
      companies: companies,
      body: description,
      image: imgSrc,
      end_date:dayjs(isRecurringEvent ? date : endDate).format("YYYY-MM-DD") + " " + dayjs(endDate, "hh:mm a").format("HH:mm:ss"),
      start_date:dayjs(date).format("YYYY-MM-DD") + " " + dayjs(date, "hh:mm a").format("HH:mm:ss"),
      event_location: !selectedOption ? location : null,
      event_link: selectedOption ? eventLink : null,
      event_points: eventPointValue,
      all_day_event: allDayEvent,
      time_zone: time_zone,
      ...emailOption,
      is_recurring_event: isRecurringEvent,
      recurring_type: isRecurringEvent ? recurringEvent : null,
      recurring_event_limit: isRecurringEvent ? eventLimitValue : 0,
      event_flyer: flayerValue,
      company_city_states: city_location,
      is_lunch_and_learn_event:1,
      coach:this.state.coachId,
      event_for_spouse: this.state.isSpouse ? 1 : 0,
      event_for_dependent:this.state.isDependent ? 1 : 0
    };
    if (role==="ADMIN" && selectedCompanyCheckBox===1 && this.props?.locationDetails && this.props.locationDetails?.length>0) {
      // EventDetails.company_city_states = city_location;
      EventDetails.event_for_spouse = this.state.isSpouse ? 1 : 0;
      EventDetails.event_for_dependent = this.state.isDependent ? 1 : 0;
      if (this.props.locationDetails && this.props.locationDetails.length > 0) {
        if(city_location.length > 0){
          (EventDetails.is_for_employee = 1)
        }else{
          (EventDetails.is_for_employee = 0)
        }  
      } else {
        if (companyDetails["spouse_or_dependent"]) {
          EventDetails.is_for_employee = this.state.isEmployee ? 1 : 0;
        } else {
          EventDetails.is_for_employee = 1;
        }
      }
    }
    if (locationPermission) {
      // EventDetails.company_city_states = city_location;
      EventDetails.event_for_spouse = this.state.isSpouse ? 1 : 0;
      EventDetails.event_for_dependent = this.state.isDependent ? 1 : 0;
      if (this.props.locationDetails && this.props.locationDetails?.length > 0) {
        // city_location.length > 0
        //   ? (EventDetails.is_for_employee = 1)
        //   : (EventDetails.is_for_employee = 0);
        if(city_location.length > 0){
          (EventDetails.is_for_employee = 1)
        }
        else{
          (EventDetails.is_for_employee = 0)
        }
      } else {
        if (companyDetails["spouse_or_dependent"]) {
          EventDetails.is_for_employee = this.state.isEmployee ? 1 : 0;
        } else {
          EventDetails.is_for_employee = 1;
        }
      }
    }
    if (addLimitCheckbox) {
      EventDetails.user_limit = limitUser;
    }


    if(usersToInvite === 0){
      EventDetails.company_departments = null;
      EventDetails.company_city_states = null;
      EventDetails.teams = null;
      EventDetails.is_for_employee = 1;
    }else if(usersToInvite === 1){
      EventDetails.company_departments = departments;
      EventDetails.company_city_states = null;
      EventDetails.teams = null;
      EventDetails.is_for_employee = 0;
    }else if(usersToInvite === 2){
      EventDetails.company_departments = null;
      EventDetails.company_city_states = city_location;
      EventDetails.teams = null;
      EventDetails.is_for_employee = 0;
    }else if(usersToInvite === 3){
      EventDetails.teams = teams;
      EventDetails.is_team_event=1;
      EventDetails.company_departments = null;
      EventDetails.company_city_states = null;
      EventDetails.is_for_employee = 0;
    }

    if(isOnDemandEvent){
      EventDetails.image = null;
      EventDetails.image_url = imageUrl;

    }

    console.log("EventDetails",EventDetails);
    // createEvent(EventDetails, this.failedFunction);
    this.PostEvent(EventDetails)
  };

  PostEvent = async(payload)=>{
    
    try{
      const res = await fetchApi(CreateEventAPI, "POST",payload);
      if(res.message){
        toast.error(res.message);
        this.setState({
          buttonStatus: false,
        });
      }else{
        toast.success(res.data[0]);
        this.props.history.push({pathname:"/company/events",state:"updateData"})
      }

    }catch(error){
      toast.error(error)

    }
  }

  onChangeDescription = (evt) => {
    const newContent = evt.getData();
    const trimmedString = newContent.replace(/\s|&nbsp;/g, "");
    trimmedString.length === 0 ||
    trimmedString.length === 7 ||
    trimmedString == "<p></p><p></p>"
      ? this.setState({ description: "" })
      : this.setState({ description: newContent });
  };
  changeDateTimeView = (value) => {
    if (value === "date") {
      this.setState({
        date: dayjs(),
        endDate: dayjs().add(1, 'day'),
        showDateTimeInput: value,
        allDayEvent: 1,
        showFrequency: false
      });
    } else {
      this.setState({
        date: dayjs(),
        endDate:  dayjs().add(1, 'day'),
        showDateTimeInput: "",
        allDayEvent: 0,
        showFrequency: true
      });
    }
  };

  toggleRadioButton = (e) => {
    this.setState({ selectedOption: e });
    if (e === 1) {

      this.setState({ location: "", eventLink: this.state.videoUrl,iseventUrl:true,      date: this.state.date,
        endDate: dayjs(this.state.date).add(2, 'day'),
        time: "12:00 am",
        endTime: "11:59 pm",
        showDateTimeInput: "date",
        allDayEvent: 1, });
    } else {
      this.setState({ eventLink: null,iseventUrl:false });
    }
  };

  toggleButton = (e) => {
    this.setState({ selectLocation: parseInt(e.target.value,10) }, () => {});
  };

  toggleFlayer = (e) => {
    this.setState({ selectFlayer: e });
  };

  companyToggleButton = (e) => {
    this.setState({
      selectedCompanyCheckBox: e,
      companies: [],
      selectedCompanyValue: "",
    });
    if (e === 0) {
      this.setState({
        city_location: [],
      });
    }
  };

  inviteToggleButton = (e) => {
    this.setState({
      usersToInvite: e,
      // companies: [],
      // selectedCompanyValue: "",
    });
    if (e === 0) {
      this.setState({
        // city_location: [],
      });
    }
  };

  launchToggleButton = (e) => {
    this.setState({
      launch: e,
      // companies: [],
      // selectedCompanyValue: "",
    });
    if (e === 0) {
      this.setState({
        // city_location: [],
      });
    }
  };

  removeCommaFromFirstPlace = (str) => {
    if (str.charAt(0) === ",") {
      return str.substring(1);
    }
    return str;
  };


  updateStep = (value) => {
    this.setState({ step: value, buttonClickScrollTop: true });
  };

  nextStep = (value) => {
    this.setState({
      step: value,
      buttonClickScrollTop: true,
      buttonStatus: false,
    });
  };

  isUrl = (eventUrl) => {
    const UrlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
    UrlRegex.test(eventUrl);
    if (UrlRegex.test(eventUrl)) {
      this.setState({
        iseventUrl: true,
        iseventUrlMsg: "",
      });
    } else {
      this.setState({
        iseventUrl: false,
        iseventUrlMsg: "Please enter valid URL",
      });
    }
  };


  redirect = () => {
    this.props.history.goBack();
  };

  removeHtmlTags = (str) => str.replace(/<[^>]*>/g, "");

  renderHeading = () => (
    <ChallengeHeading color={"#005c87"}>
      <div style={{marginRight:"12px"}}>
        <HoverButton
          title={this.props.t("Close")}
          width="24px"
          height="24px"
          svgPath={closeIcon()}
          onClick={this.redirect}
          activeColor={"#007AB1"}
          fillOpacity={1}
        />
      </div>
      {this.props.t("Launch the lunch and learns")}
    </ChallengeHeading>
  );

  stepOneForm = () => {
    const {
      title,
      description,
      step,
      isOnDemandEvent
    } = this.state;
    const { t } = this.props;
    return (
      <Layout>
        <div className="main" >
          {this.renderHeading()}
          <Container color="#005C87">
            <div className="stepsNameHighlight">
              <StepsHeading stepCount={step} type="event" />
            </div>
            <div className="form">
              <div className="heading">
                <div className="step">{step}</div>
                <div className="headingName" style={{color:"#005c87"}}>{this.props.t("Event Introduction")}</div>
              </div>
              <div className="formBody">
                <InputContainer>
                  <StyledLabel color={"#005c87"}>{this.props.t("Title of the Event")}{<span>*</span>}</StyledLabel>
                  <StyledInputV2
                    placeholder={t("Write title here...")}
                    name="title"
                    onChange={this.onChangeInput}
                    value={title}
                    maxLength="100"
                    style={{background:"white"}}
                    pColor={"#649bb3"}
                    color="#005c87"
                    border={"1px solid rgba(0, 122, 177, 0.30)"}
                    disabled={isOnDemandEvent}
                  />
                </InputContainer>
                <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px'}}/>

                <StyledLabel color={"#005c87"} style={{marginTop:"0px"}}>{this.props.t("Event image")}{<span>*</span>}</StyledLabel>
                <UploadPhotoContainer>
                  <div className="innerDivImageContainer">
                    {this.state.imgSrc ? (
                      <RemoveImageV2 >
                        <img alt="profilre-img" src={this.state.imgSrc}></img>
                        <div className="main-div" onClick={()=>{!isOnDemandEvent && this.removePhoto()}}>
                          <div className="icon-div" ><div className="sub-icon-div">{closeIcon("white","8","8")}</div></div>
                        </div>
                      </RemoveImageV2>
                    ) : (
                      <div className="uploadImage">

                        <InputImage
                          className="edit-profile-pic "
                          imageReload={1} 
                          style={{background:"unset",color:"#005c87"}}
                        >
                          <div className="middle-section">
                            <div>{UploadIcon()}</div>
                            <div className="title">{this.props.t("Upload File")}</div>
                            <div className="sub-title">{this.props.t(".jpeg, .png")}</div>
                            
                          </div>
                          {/* <i className="addImg">{this.props.t("Upload Photo")}</i> */}
                          <input
                            id="event-upload-file"
                            type="file"
                            name="user"
                            accept=".jpeg, .png, .jpg"
                            multiple={false}
                            onChange={(e) => this.onChange(e)}
                            onClick={(e) =>
                              e.target.files[0] && this.onChange(e)
                            }
                          />
                        </InputImage>
                      </div>
                    )}

                  </div>
                </UploadPhotoContainer>
                <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px'}}/>
                <InputContainer>
                  <StyledLabel style={{marginTop:"0px"}} color={"#005c87"}>{this.props.t("About the Event")}{<span>*</span>}</StyledLabel>
                  <EditorContainer>
                    <Editor
                      content={description}
                      onChange={this.onChangeDescription}
                      disabled={isOnDemandEvent}
                    />
                  </EditorContainer>
                </InputContainer>
              </div>
            </div>
          </Container>
        </div>
      </Layout>
    );
  };

  DropdownIndicator = () => (
    <svg
      width="12"
      height="20"
      viewBox="0 0 12 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 10C12 10.3585 11.8559 10.7169 11.5684 10.9901L2.51663 19.5897C1.94083 20.1368 1.00725 20.1368 0.431681 19.5897C-0.143894 19.0429 -0.143894 18.1561 0.431681 17.609L8.44122 10L0.43196 2.39098C-0.143614 1.84394 -0.143614 0.957264 0.43196 0.410484C1.00753 -0.136826 1.94111 -0.136826 2.51691 0.410484L11.5687 9.00992C11.8563 9.28333 12 9.64172 12 10Z"
        fill="#007AB1"
      />
    </svg>
  );

  toggleMenu = () => {
    this.setState((prevState) => ({
      menuIsOpen: !prevState.menuIsOpen,
    }));
  };

  stepTwoForm = () => {
    const {
      step,
      iseventUrlMsg,
      location,
      eventLink,
      addLimitCheckbox,
      limitUser,
      selectedOption,
      eventPointValue,
      eventPoints,
      flayerName,
      selectFlayer,
      allCoaches,
      coachName,
      isOnDemandEvent
    } = this.state;
    const { t } = this.props;

    return (
      <Layout>
        <div className="main">
          {this.renderHeading()}
          <Container color={"#005c87"}>
            <div className="stepsNameHighlight">
              <StepsHeading stepCount={step} type="event" />
            </div>
            <div className="form">
              <div className="heading">
                <div className="step">{step}</div>
                <div className="headingName">
                  {this.props.t("How would you like to customize your event?")}
                </div>
              </div>
              <div className="formBody">
                <div>

                  <StyledLabel color={"#005c87"}>{this.props.t("Type of event")}?{<span>*</span>}</StyledLabel>
                  <div className="radioButtonDiv">
                    <span onClick={() => this.toggleRadioButton(0)}>
                      <RadioButton
                        id="onsite"
                        handler={this.toggleRadioButton}
                        value={0}
                        isChecked={selectedOption === 0}
                        label={t("Onsite Event")}
                        challengeCreate={true}
                      />
                    </span>
                    <span onClick={() => this.toggleRadioButton(1)}>
                      <RadioButton
                        id="virtual"
                        handler={this.toggleRadioButton}
                        value={1}
                        isChecked={selectedOption === 1}
                        label={t("Virtual Event")}
                        challengeCreate={true}
                        style={{color:"#005c87"}}
                      />
                    </span>
                  </div>
                  {selectedOption === 0 ? (
                    <StyledInputV2
                      placeholder={t("Enter conference room number, location, etc")}
                      name="location"
                      value={location}
                      onChange={this.onLocationChange}
                      locationInput
                      color={"#005c87"}
                      bgColor={"white"}
                      pColor={"#649bb3"}
                      border={"1px solid #afcbd3"}
                      style={{marginTop:"12px"}}
                    />
                  ) : (
                    <React.Fragment>
                      <StyledInputV2
                        placeholder={t("app.woliba.io/events/on-demand/medical/147")}
                        name="eventLink"
                        onChange={this.onChangeInput}
                        value={eventLink}
                        color={"#005c87"}
                        bgColor={"white"}
                        pColor={"#649bb3"}
                        border={"1px solid #afcbd3"}
                        style={{marginTop:"12px"}}
                        disabled={isOnDemandEvent}
                      />
                      <span style={{ color: "red" }}>{iseventUrlMsg}</span>
                    </React.Fragment>
                  )}
                  <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px'}}/>
                  <InputContainer>
                    <StyledLabelV2>
                      <div className="name" style={{color:"#005c87"}}>
                        {this.props.t("Event limit users")}{<span>*</span>}
                      </div>
                      <div className="radio" style={{color:"#005c87"}}>
                        <span style={{width:"auto", display:"flex",alignItems:"center",cursor:"pointer", marginRight:"24px"}}   onClick={() => this.changeLimitCheckBox(false)}>
                          <CustomRadioButton
                            onClick={() => this.changeLimitCheckBox(false)}
                            style={{color:"#005c87",border:"1px solid #669db7"}}
                            background={"#85c0ea"}
                          >
                            {" "}
                            {!addLimitCheckbox && <div></div>}
                          </CustomRadioButton>
                          {this.props.t("No Limit")}
                        </span>
                        <span style={{width:"auto", display:"flex",alignItems:"center",cursor:"pointer"}}   onClick={() => this.changeLimitCheckBox(true)}>
                          <CustomRadioButton
                            onClick={() => this.changeLimitCheckBox(true)}
                            style={{color:"#005c87",border:"1px solid #669db7"}}
                            background={"#85c0ea"}
                          >
                            {" "}
                            {addLimitCheckbox && <div></div>}
                          </CustomRadioButton>
                          {this.props.t("Add Limit")}
                        </span>
                      </div>
                    </StyledLabelV2>
                    <StyledInputV2
                      placeholder={t("Enter limit here...")}
                      type={addLimitCheckbox ? "number" : "text"}
                      name="limitUser"
                      onChange={this.onChangeInput}
                      value={addLimitCheckbox ? limitUser : t("No Limit")}
                      min="1"
                      pattern="[0-9]*"
                      disabled={!addLimitCheckbox}
                      color={"#005c87"}
                      bgColor={"white"}
                      pColor={"#649bb3"}
                      border={"1px solid #afcbd3"}
                    />
                  </InputContainer>

                  <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px'}}/>

                  <CommonDropdown
                    tileContainerStyle={{ width: "100%",padding:"0px 0px 20px 0px" }}
                    dropdownStyle={{ top: "90px" }}
                    labelText={this.props.t("Event point value")}
                    isRequired={true}
                    dropdownOpen={this.state.so}
                    placeholder={this.props.t("Select Point Value")}
                    title={
                      eventPointValue
                    }
                    id="dropdown-recurring"
                    onClick={() => this.setState({ so: !this.state.so })}
                    data={eventPoints}
                    onSelect={this.onSelectPoints}
                    active={eventPointValue}
                    onSelectParameter={["eventPointValue", "value", "key"]}
                    valueString={"Points"}
                    itemValue={true}
                    valueIndex={1}
                    labelMargin={"0px"}
                  />

                  <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px',display:"flex"}}/>

                  {!this.props.history?.location?.state?.isOnDemandEvent &&(<><CommonDropdown
                    tileContainerStyle={{ width: "100%",padding:"0px 0px 20px 0px" }}
                    dropdownStyle={{ top: "90px" }}
                    labelText={this.props.t("Event Host")}
                    isRequired={false}
                    dropdownOpen={this.state.coachSo}
                    placeholder={this.props.t("Select Option")}
                    title={
                      coachName
                        ? `${coachName}`
                        : this.props.t("Select Coach")
                    }
                    id="dropdown-recurring"
                    onClick={() => this.setState({ coachSo: !this.state.coachSo })}
                    data={allCoaches}
                    onSelect={this.onSelectCoach}
                    active={coachName}
                    onSelectParameter={["coachName", "name"]}
                    // valueString={"name"}
                    itemValue={true}
                    valueIndex={1}
                    labelMargin={"0px"}
                  />

                  <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px 0px 0px',display:"flex"}}/></>)}

                 
                  <StyledLabel color={"#005c87"}>
                    {this.props.t("Would you like to add promotional document?")} {<span>*</span>}
                  </StyledLabel>
                  <div className="radioButtonDiv">
                    <span onClick={() => this.toggleFlayer(0)}>
                      <RadioButton
                        id="flyer-yes"
                        handler={this.toggleFlayer}
                        value={0}
                        isChecked={selectFlayer === 0}
                        label={"Yes"}
                        challengeCreate={true}
                        style={{color:"#005c87"}}
                      />
                    </span>
                    <span onClick={() => this.toggleFlayer(1)}>
                      <RadioButton
                        id="flyer-no"
                        handler={this.toggleFlayer}
                        value={1}
                        isChecked={selectFlayer === 1}
                        label={"No"}
                        challengeCreate={true}
                        style={{color:"#005c87"}}
                      />
                    </span>
                  </div>

                  

                  {selectFlayer === 0 && ( <UploadPhotoContainer>
                    <div className="innerDivImageContainer">
                      <div className="uploadImage">
 
                        <InputImage
                          className="edit-profile-pic "
                          imageReload={1} 
                          style={{background:"unset",color:"#005c87",width:"100%", display:"flex",alignItems:"center",justifyContent:"center"}}
                        >
                          <div className="middle-section" style={{textAlign:"center"}}>
                            {flayerName
                              ? (<React.Fragment>   
                                <CircleNew width="70px" height="70px">
                                  <CircularProgressBar
                                    strokeWidth="7"
                                    sqSize="64"
                                    percentage={100}
                                    color="#76AB78"
                                    hideText={true}
                                    // textValue="Culture Score"
                                  />
                                </CircleNew>                           
                                <span className="flyer-name">{flayerName}</span></React.Fragment>)
                              : (<React.Fragment>                              
                                <div>{UploadIcon()}</div>
                                <div className="title">{this.props.t("Upload File")}</div>
                                <div className="sub-title">{this.props.t(".pdf, .jpeg, .png")}</div></React.Fragment>)}

                                
                          </div>
                          <input
                            id="event-upload-file"
                            type="file"
                            name="user"
                            accept=".jpeg, .png, .jpg, .pdf"
                            multiple={false}
                            onChange={(e) => this.uploadPDFFile(e)}
                            onClick={(e) =>
                              e.target.files[0] && this.uploadPDFFile(e)
                            }
                          />
                        </InputImage>
                        {flayerName && ( 
                          <RemoveImageV2 >
                            <div className="main-div" onClick={flayerName ? () => this.removePDF() : ""}>
                              <div className="icon-div" ><div className="sub-icon-div">{closeIcon("white","8","8")}</div></div>
                            </div>
                          </RemoveImageV2>)}
                      </div>
   
                    </div>
                  </UploadPhotoContainer>)}
                  
                </div>
              </div>
            </div>
          </Container>
        </div>
      </Layout>
    );
  };

  stepThreeForm = () => {
    const {
      companies,
      city_location,
      companiesPermission,
      step,
      selectedCompanyValue,
      selectedCompanyCheckBox,
      // isEmployee,
      isDependent,
      isSpouse,
      usersToInvite,
      allTeams,
      filteredCompanies,
      departments,
      teams,
      companyInfo
    } = this.state;
    const { allCompanies, locationDetails, t, role, departmentDetails } = this.props;
    return (
      <Layout>
        <div className="main">
          {this.renderHeading()}
          <Container color={"#005c87"}>
            <div className="stepsNameHighlight">
              <StepsHeading stepCount={step} type="event" />
            </div>
            <div className="form">
              <div className="heading">
                <div className="step">{step}</div>
                <div className="headingName">{this.props.t("Who would you like to invite?")}</div>
              </div>
              <div className="formBody">
                {companiesPermission && (
                  <StyledLabel color="#005c87">
                    {this.props.t("Host your event for?")}{<span>*</span>}
                  </StyledLabel>
                )}
                {companiesPermission && (
                  <div className="radioButtonDiv">
                    <span onClick={() => this.companyToggleButton(0)}>
                      <RadioButton
                        id="global"
                        handler={this.companyToggleButton}
                        value={0}
                        isChecked={selectedCompanyCheckBox === 0}
                        label={t("Global")}
                        challengeCreate={true}
                        style={{color:"#005c87"}}
                      />
                    </span>
                    <span onClick={() => this.companyToggleButton(1)}>
                      <RadioButton
                        id="company"
                        handler={this.companyToggleButton}
                        value={1}
                        isChecked={selectedCompanyCheckBox === 1}
                        label={t("For an Individual Company")}
                        challengeCreate={true}
                        style={{color:"#005c87"}}
                      />
                    </span>
                  </div>
                )}
                {companiesPermission ? (<>

                  <Heading>
                    <div className="name" style={{color:"#005c87"}}>{this.props.t(`Select companies to invite`)} &nbsp; <span> { companies.length > 0 ? "(" + companies.length + " Companies Selected)" : ""}</span></div>
                    <SearchFilter1 style={{width:"416px",maxWidth:"416px"}}>
                      <StyledInputV2
                        type="text" placeholder={t("Search company")}
                        value={this.state.searchTerm} 
                        onChange={this.handleSearch} 
                        // onKeyDown={this.handleKeyDown}
                        style={{border:"none"}}
                      />
                      <SearchIcon> {SearchButtonIcon()}</SearchIcon>
                    </SearchFilter1>
                  </Heading>
                    
                  <Locationwrapper background="white">
                    {selectedCompanyCheckBox === 0 ? <div className="checkBoxWidth">
                      <span style={{color:"#005c87"}}>
                        <CustomRadioButton
                          onClick={(e) =>
                            this.selectAllCompanies(e, allCompanies)
                          }
                          style={{color:"#005c87",border:"1px solid #669db7"}}
                          background={"#85c0ea"}
                        >
                          {" "}
                          {allCompanies &&
                            companies.length === allCompanies.length && (
                            <div></div>
                          )}{" "}
                        </CustomRadioButton>{" "}
                        {this.props.t("All Companies")}
                      </span>
                    </div>: null} 
                    {filteredCompanies && filteredCompanies.length > 0
                      ? filteredCompanies.map((company, index) => (
                        <div className="checkBoxWidth" key={index}>
                          <span style={{color:"#005c87"}}>
                            <CustomRadioButton
                              onClick={() =>{
                                selectedCompanyCheckBox === 0 ? this.onCheckboxChange(company.id,company.company_name) : selectedCompanyCheckBox === 1 ? this.onSelectLocation(company) : null
                              }
                              }
                              background="#afcbd3"
                              style={{border:"1px solid #649bb3"}}
                              id={company.id + "1"}
                            >
                              {" "}
                              {companies.includes(company.id) && (
                                <img src={ImageUrl+"/images/newRadioCheck.svg"}></img>
                              )}{" "}
                            </CustomRadioButton>
                            {company.company_name}
                          </span>
                        </div>
                      )): null}
                  </Locationwrapper>
                    
                    
                </>) : null}

                {role === "ADMIN" && (<Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px 0px 0px',display:"flex"}}/>)}

                <div className="name" style={{color:"#005c87",marginTop: "20px",marginBottom:"16px"}} >
                  {this.props.t("Select Users")}
                  <span className="astric">*</span>
                </div>
                <div className="radioButtonDiv" style={{zIndex:"10",position:"relative"}}>
                  <span onClick={() => this.inviteToggleButton(0)}>
                    <RadioButton
                      id="all-users"
                      handler={this.inviteToggleButton}
                      value={0}
                      isChecked={usersToInvite === 0}
                      label={t("All Users")}
                      challengeCreate={true}
                      style={{color:"#005c87"}}
                    />
                  </span>
                  {(role != "ADMIN" || selectedCompanyCheckBox === 1) && departmentDetails?.length > 0 && (<span onClick={() => this.inviteToggleButton(1)}>
                    <RadioButton
                      id="departments"
                      handler={this.inviteToggleButton}
                      value={1}
                      isChecked={usersToInvite === 1}
                      label={t("Departments")}
                      challengeCreate={true}
                      style={{color:"#005c87"}}
                    />
                  </span>)}
                  {(role != "ADMIN" || selectedCompanyCheckBox === 1) && locationDetails?.length > 0 &&(<span onClick={() => this.inviteToggleButton(2)}>
                    <RadioButton
                      id="locations"
                      handler={this.inviteToggleButton}
                      value={2}
                      isChecked={usersToInvite === 2}
                      label={t("Locations")}
                      challengeCreate={true}
                      style={{color:"#005c87"}}
                    />
                  </span>)}
                  {(role != "ADMIN" || selectedCompanyCheckBox === 1) && allTeams.length > 0 &&( <span onClick={() => this.inviteToggleButton(3)}>
                    <RadioButton
                      id="teams"
                      handler={this.inviteToggleButton}
                      value={3}
                      isChecked={usersToInvite === 3}
                      label={t("Teams")}
                      challengeCreate={true}
                      style={{color:"#005c87"}}
                    />
                  </span>)}
                </div>
                {(role === "ADMIN" &&
                  locationDetails && 
                  locationDetails?.length > 0 &&
                  selectedCompanyCheckBox === 1 &&
                  selectedCompanyValue.length > 0 && usersToInvite === 2) ||
                (locationDetails &&
                  locationDetails?.length > 0 &&
                  role != "ADMIN"  && usersToInvite === 2 ) ? (<Heading>
                    <div className="name" style={{color:"#005c87"}}>
                      {this.props.t("Select Locations")}
                      <span className="astric">*</span>
                    </div>
                  </Heading>
                  ) : null}
                {(role === "ADMIN" &&
                usersToInvite === 2 && locationDetails &&
                  locationDetails?.length > 0 &&
                  selectedCompanyCheckBox === 1 &&
                  selectedCompanyValue.length > 0) ||
                (locationDetails &&
                  locationDetails?.length > 0 &&
                  role != "ADMIN"  && usersToInvite === 2 ) ? (
                    <Locationwrapper  background="white" style={{zIndex:"10",position:"relative"}}>
                      <div className="checkBoxWidth" >
                        <span style={{color:"#005c87"}}>
                          <CustomRadioButton
                            onClick={(e) =>
                              this.selectAllLocation(e, locationDetails)
                            }
                            style={{color:"#005c87",border:"1px solid #669db7"}}
                            background={"#85c0ea"}
                          >
                            {" "}
                            {locationDetails &&
                              city_location.length === locationDetails?.length && (<div></div>)}{" "}
                          </CustomRadioButton>{" "}
                          {this.props.t("All Locations")}
                        </span>
                      </div>
                      {locationDetails && locationDetails?.length > 0
                        ? locationDetails.map((loc, index) => (
                          <div className="checkBoxWidth" key={index}>
                            <span style={{color:"#005c87"}}>
                              <CustomRadioButton
                                onClick={() => this.onChangeLocation(loc.id)}
                                background="#afcbd3"
                                style={{border:"1px solid #649bb3"}}
                              >
                                {" "}
                                {city_location.includes(loc.id) && (
                                  // <div></div>
                                  <img src={ImageUrl+"/images/newRadioCheck.svg"}></img>
                                )}{" "}
                              </CustomRadioButton>
                              {this.removeCommaFromFirstPlace(loc.location)}
                            </span>
                          </div>
                        ))
                        : null}
                    </Locationwrapper>
                  ) : null}

                {/* Dpartment Listing */}

                {(role === "ADMIN" &&
                  departmentDetails && 
                  departmentDetails?.length > 0 &&
                  selectedCompanyCheckBox === 1 &&
                  selectedCompanyValue.length > 0 && usersToInvite === 1) ||
                (departmentDetails &&
                  departmentDetails?.length > 0 &&
                  role != "ADMIN"  && usersToInvite === 1 ) ? (<Heading>
                    <div className="name" style={{color:"#005c87"}}>
                      {this.props.t("Select Departments")}
                      <span className="astric">*</span>
                    </div>
                  </Heading>
                  ) : null}
                {(role === "ADMIN" &&
                usersToInvite === 1 && departmentDetails &&
                  departmentDetails?.length > 0 &&
                  selectedCompanyCheckBox === 1 &&
                  selectedCompanyValue.length > 0) ||
                (departmentDetails &&
                  departmentDetails?.length > 0 &&
                  role != "ADMIN"  && usersToInvite === 1 ) ? (
                    <Locationwrapper  background="white" style={{zIndex:"10",position:"relative"}}>
                      <div className="checkBoxWidth" >
                        <span style={{color:"#005c87"}}>
                          <CustomRadioButton
                            onClick={(e) =>
                              this.selectAllDepartment(e, departmentDetails)
                            }
                            style={{color:"#005c87",border:"1px solid #669db7"}}
                            background={"#85c0ea"}
                          >
                            {" "}
                            {departmentDetails &&
                              departments.length === departmentDetails?.length && (<div></div>)}{" "}
                          </CustomRadioButton>{" "}
                          {departmentDetails &&
                          departments.length === departmentDetails?.length
                            ? this.props.t("Deselect All")
                            : this.props.t("Select All")}
                        </span>
                      </div>
                      {departmentDetails && departmentDetails?.length > 0
                        ? departmentDetails.map((dep, index) => (
                          <div className="checkBoxWidth" key={index}>
                            <span style={{color:"#005c87"}}>
                              <CustomRadioButton
                                onClick={() => this.onChangeDepartment(dep.id)}
                                background="#afcbd3"
                                style={{border:"1px solid #649bb3"}}
                              >
                                {" "}
                                {departments.includes(dep.id) && (
                                  // <div></div>
                                  <img src={ImageUrl+"/images/newRadioCheck.svg"}></img>
                                )}{" "}
                              </CustomRadioButton>
                              {this.removeCommaFromFirstPlace(dep.department)}
                            </span>
                          </div>
                        ))
                        : null}
                    </Locationwrapper>
                  ) : null}

                {/* Teams Listing */}

                {(role === "ADMIN" &&
                  allTeams && 
                  allTeams.length > 0 &&
                  selectedCompanyCheckBox === 1 &&
                  selectedCompanyValue.length > 0 && usersToInvite === 3) ||
                (allTeams &&
                  allTeams.length > 0 &&
                  role != "ADMIN"  && usersToInvite === 3 ) ? (<Heading>
                    <div className="name" style={{color:"#005c87"}}>
                      {this.props.t("Select Teams")}
                      <span className="astric">*</span>
                    </div>
                  </Heading>
                  ) : null}
                {(role === "ADMIN" &&
                usersToInvite === 3 && allTeams &&
                  allTeams.length > 0 &&
                  selectedCompanyCheckBox === 1 &&
                  selectedCompanyValue.length > 0) ||
                (allTeams &&
                  allTeams.length > 0 &&
                  role != "ADMIN"  && usersToInvite === 3 ) ? (
                    <Locationwrapper  background="white" style={{zIndex:"10",position:"relative"}}>
                      <div className="checkBoxWidth" >
                        <span style={{color:"#005c87"}}>
                          <CustomRadioButton
                            onClick={(e) =>
                              this.selectAllTeam(e, allTeams)
                            }
                            style={{color:"#005c87",border:"1px solid #669db7"}}
                            background={"#85c0ea"}
                          >
                            {" "}
                            {allTeams &&
                              teams.length === allTeams.length && (<div></div>)}{" "}
                          </CustomRadioButton>{" "}
                          {allTeams &&
                          teams.length === allTeams.length
                            ? this.props.t("Deselect All")
                            : this.props.t("Select All")}
                        </span>
                      </div>
                      {allTeams && allTeams.length > 0
                        ? allTeams.map((team, index) => (
                          <div className="checkBoxWidth" key={index + team}>
                            <span style={{color:"#005c87"}} >
                              <CustomRadioButton
                                onClick={() => this.onChangeTeam(team.id)}
                                background="#afcbd3"
                                style={{border:"1px solid #649bb3"}}
                              >
                                {" "}
                                {teams.includes(team.id) && (
                                  // <div></div>
                                  <img src={ImageUrl+"/images/newRadioCheck.svg"}></img>
                                )}{" "}
                              </CustomRadioButton>
                              {this.removeCommaFromFirstPlace(team.name)}
                            </span>
                          </div>
                        ))
                        : null}
                    </Locationwrapper>
                  ) : null}

                {(companyInfo?.show_spouse|| companyInfo?.show_dependent)&&(companies?.length>0&&selectedCompanyCheckBox==1)?<Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px 0px 0px',display:"flex"}}/>:null}

                {/* Spouse Dependent */}
                {(companyInfo?.show_spouse|| companyInfo?.show_dependent)?<div>{role === "ADMIN" && selectedCompanyCheckBox === 1 && selectedCompanyValue ? (
                  <Heading>
                    {locationDetails && locationDetails?.length > 0 ? (
                      <div className="name" style={{color:"#005c87"}}>{("Are you also interested in extending an invitation?")}</div>
                    ) : (
                      <div className="name" style={{color:"#005c87"}}>
                        {this.props.t("Select the Who you wants to Invite")}
                        <span className="astric">*</span>
                      </div>
                    )}
                  </Heading>
                ) : (
                  role != "ADMIN" && (
                    <Heading>
                      {locationDetails &&
                      locationDetails?.length > 0 &&
                      role != "ADMIN" ? (<div className="name" style={{color:"#005c87"}}>{this.props.t("Do you also want to invite")}</div>) : (
                          <div className="name" style={{color:"#005c87"}}>
                            {this.props.t("Select the Who you wants to Invite")}
                            <span className="astric">*</span>
                          </div>
                        )}
                    </Heading>
                  )
                )}

                {role === "ADMIN" && selectedCompanyCheckBox === 1 && selectedCompanyValue ? (
                  <Locationwrapper  background="white">
                    {companyInfo?.show_spouse ? <div className="checkBoxWidth">
                      <span style={{color:"#005c87"}}>
                        <CustomRadioButton
                          onClick={() => this.selectSpouseDependent("isSpouse")}
                          background="#afcbd3"
                          style={{border:"1px solid #649bb3"}}
                        >
                          {" "}
                          {isSpouse && (
                            // <div></div>
                            <img src={ImageUrl+"/images/newRadioCheck.svg"}></img>
                          )}{" "}
                        </CustomRadioButton>
                        {this.props.t("Spouse")}
                      </span>
                    </div>: null}
                    {companyInfo?.show_dependent ?<div className="checkBoxWidth">
                      <span>
                        <CustomRadioButton
                          onClick={() =>
                            this.selectSpouseDependent("isDependent")
                          }
                        >
                          {" "}
                          {isDependent && (
                            // <div></div>
                            <img src={ImageUrl+"/images/newRadioCheck.svg"}></img>
                          )}{" "}
                        </CustomRadioButton>
                        {this.props.t("Dependent")}
                      </span>
                    </div>: null}
                  </Locationwrapper>
                ) : (
                  role != "ADMIN" && (
                    <Locationwrapper  background="white">
                      {companyInfo?.show_spouse ? <div className="checkBoxWidth">
                        <span style={{color:"#005c87"}}>
                          <CustomRadioButton
                            onClick={() =>
                              this.selectSpouseDependent("isSpouse")
                            }
                            background="#afcbd3"
                            style={{border:"1px solid #649bb3"}}
                          >
                            {" "}
                            {isSpouse && (
                              <img src={ImageUrl+"/images/newRadioCheck.svg"}></img>
                            )}{" "}
                          </CustomRadioButton>
                          {this.props.t("Spouse")}
                        </span>
                      </div>:null}
                      {companyInfo?.show_dependent ?<div className="checkBoxWidth">
                        <span>
                          <CustomRadioButton
                            onClick={() =>
                              this.selectSpouseDependent("isDependent")
                            }
                          >
                            {" "}
                            {isDependent && (
                              <img src={ImageUrl+"/images/newRadioCheck.svg"}></img>
                            )}{" "}
                          </CustomRadioButton>
                          {this.props.t("Dependent")}
                        </span>
                      </div>:null}
                    </Locationwrapper>
                  )
                )}</div>:null}
              </div>
            </div>
          </Container>
        </div>
      </Layout>
    );
  };

  removePDF = () => {
    this.setState({
      flayerValue: "",
      flayerName: "",
      progress: 0,
    });
  };

  stepFourForm = () => {
    const {
      step,
      showDateTimeInput,
      date,
      endDate,
      time_zone,
      recurringEvent,
      eventLimitValue,
      // menuIsOpen,
      companiesPermission,
      launch,
      emailOption,
      iseventUrl
    } = this.state;


    let emailOptionKeys = Object.keys(emailOption);

    const { t } = this.props;

    const eventWeeklyLimits = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    const eventLimits = eventWeeklyLimits;
    const customStyles = {
      control: (base, state) => ({
        ...base,
        boxShadow: state.isFocused ? null : null,
        "&:hover": {
          borderColor: state.isFocused ? "hsl(0,0%,70%)" : "hsl(0,0%,70%)",
        },
      }),
    };
    let timeZoneValue;
    timezones["timezones"].map((zone) => {
      if (zone.value === time_zone) {
        timeZoneValue = zone.label;
      }
    });
    return (
      <Layout>
        <div className="main">
          {this.renderHeading()}
          <Container color={"#005c87"}>
            <div className="stepsNameHighlight">
              <StepsHeading stepCount={step} type="event" />
            </div>
            <div className="form">
              <div className="heading">
                <div className="step">{step}</div>
                <div className="headingName">{this.props.t("When would you like to launch your event?")}</div>
              </div>
              <div className="formBody">

                {companiesPermission && (
                  <StyledLabel color="#005c87">
                    {this.props.t("Launch Event")}{<span>*</span>}
                  </StyledLabel>
                )}

                <React.Fragment>
                  <div className="radioButtonDiv" style={{marginTop:"20px"}}>
                    <span onClick={() => this.launchToggleButton(0)}>
                      <RadioButton
                        id="global"
                        handler={this.launchToggleButton}
                        value={0}
                        isChecked={launch === 0}
                        label={t("Launch now")}
                        challengeCreate={true}
                        style={{color:"#005c87"}}
                      />
                    </span>
                    <span onClick={() => this.launchToggleButton(1)}>
                      <RadioButton
                        id="company"
                        handler={this.launchToggleButton}
                        value={1}
                        isChecked={launch === 1}
                        label={t("Schedule for later")}
                        challengeCreate={true}
                        style={{color:"#005c87"}}
                      />
                    </span>
                  </div>
                  <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px 0px 0px',display:"flex"}}/>
                </React.Fragment>

                <TitleContainer fullWidth margin="0 0 20px 0" style={{padding:"0px"}}>
                  <StyledLabel color="#005c87" style={{marginTop:"20px"}}>
                    {this.props.t("What is your preferred time zone?")}
                    {<span>*</span>}
                  </StyledLabel>
                  <SearchDropDown
                    // transform={menuIsOpen ? "rotate(90deg)" : "0"}
                    pcolor={time_zone ? "#005c87" : "#005c87"}
                    pfont={time_zone ? "rubik-medium" : "rubik"}
                    style={{ height: "50px", width: '100%'}}
                    disabled={iseventUrl}
                    
                  >
                    <Select
                      defaultValue={
                        time_zone ? timeZoneValue + (this.state.defaultTz === time_zone ? " (Default)" : "" ) : this.props.t("Select Timezone")
                      }
                      options={timezones["timezones"]}
                      onChange={(option) =>
                        !iseventUrl && this.onSelectCategory("time_zone", option.value)
                      }
                      classNamePrefix={"menu-item"}
                      styles={customStyles}
                      components={{ DropdownIndicator: !iseventUrl && this.DropdownIndicator }}
                      onMenuOpen={this.toggleMenu}
                      onMenuClose={this.toggleMenu}
                      placeholder={
                        time_zone ? timeZoneValue + (this.state.defaultTz === time_zone ? " (Default)" : "" ) : this.props.t("Select Timezone")
                      }
                      isDisabled={iseventUrl}
                    />
                  </SearchDropDown>
                </TitleContainer>
                <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px 0px 0px',display:"flex"}}/>
                <StyledLabelV2 justify style={{marginTop:"20px"}}>
                  <div className="name" style={{ color: "#005c87" }}>
                    {this.props.t("Set Date and Time")}{<span>*</span>}
                  </div>
                  <div className="radio" style={{ color: "#005c87",width:"auto" }}>
                    <CustomRadioButton
                      onClick={() => !iseventUrl && this.changeDateTimeView(showDateTimeInput ? "" : "date")}
                      style={{ color: "#005c87", border: "1px solid #669db7" }}
                      background={"#85c0ea"}
                      disabled={iseventUrl}
                    >
                      {" "}
                      {showDateTimeInput === "date" && <div></div>}
                    </CustomRadioButton>
                    {this.props.t("All Day")}
                  </div>
                </StyledLabelV2>

                {showDateTimeInput === "date" && (
                  <React.Fragment>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MUICalendarWrapper>
                        <DateTimePicker
                          label="Select Start Date"
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                          }}
                          sx={{width:"416px",color:"#005C87",borderColor:"#005C87"}}
                          onChange={(value)=>{console.log("value",value?.$d); this.onChangeDate(value?.$d, "date")}}
                          value={date}
                          disablePast={true}
                          minDate={dayjs().add(1, 'day')}
                          disabled={launch === 0}
                          slots={{openPickerIcon:CalendarIcon}}
                          
                        />
                      </MUICalendarWrapper>
                    </LocalizationProvider>
                  </React.Fragment>

                  
                )}
                {showDateTimeInput != "date" && (
                  <React.Fragment>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MUICalendarWrapper>

                        <DateTimePicker
                          label="Select Start Date"
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                          }}
                          sx={{width:"416px",color:"#005C87",borderColor:"#005C87"}}
                          onChange={(value)=>{ this.onChangeDate(value?.$d, "date")}}
                          value={date}
                          disabled={launch === 0}
                          maxDateTime={endDate}
                          slots={{openPickerIcon:CalendarIcon}}
                          disablePast={true}
                        />
                        {recurringEvent === recurringTypes[0] ? (<DateTimePicker
                          label="Select end date"
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                          }}
                          sx={{width:"416px"}}
                          onChange={(value)=>{ this.onChangeDate(value?.$d, "endDate")}}
                          value={endDate && endDate}
                          minDateTime={date}
                          slots={{openPickerIcon:CalendarIcon}}
                          disablePast={true}
                        />):(
                          <TimePicker
                            label="Select End Time"
                            viewRenderers={{
                              hours: renderTimeViewClock,
                              minutes: renderTimeViewClock,
                              seconds: renderTimeViewClock,
                            }}
                            sx={{width:"416px"}}
                            onChange={(value)=>{this.onChangeDate(value?.$d, "endDate")}}
                            slots={{openPickerIcon:CalendarIcon}}
                            disablePast={true}
                          />
                        )}
                      </MUICalendarWrapper>
                    </LocalizationProvider>
                  </React.Fragment>
                )}

                {this.state.showFrequency && <> <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px 0px 0px',display:"flex"}}/>

                  <StyledLabel color="#005c87">
                    {this.props.t("Set frequency")}{<span>*</span>}
                  </StyledLabel>
                  <div className="radioButtonDiv">
                    <span
                      onClick={() =>
                        this.onChangeTimeZone("recurringEvent", "does not repeat")
                      }
                    >
                      <RadioButton
                        id="1"
                        handler={() =>
                          this.onChangeTimeZone(
                            "recurringEvent",
                            "does not repeat"
                          )
                        }
                        value={0}
                        isChecked={recurringEvent == "does not repeat"}
                        label={t("Do not repeat")}
                        challengeCreate={true}
                        style={{ color: "#005c87" }}
                      />
                    </span>
                    <span
                      onClick={() =>
                        this.onChangeTimeZone("recurringEvent", "weekly")
                      }
                    >
                      <RadioButton
                        id="2"
                        handler={() =>
                          this.onChangeTimeZone("recurringEvent", "weekly")
                        }
                        value={1}
                        isChecked={recurringEvent == "weekly"}
                        label={t("Weekly")}
                        challengeCreate={true}
                        style={{ color: "#005c87" }}
                      />
                    </span>
                  </div>
                </>
                }

                <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px 0px 0px',display:"flex"}}/>
                {recurringEvent !== recurringTypes[0] && (
                  
                  <CommonDropdown
                    createEventUI={true}
                    style={{border: "10px solid black"}}
                    tileContainerStyle={{ width: "100%", margin: "0 0 0px 0" }}
                    dropdownOpen={this.state.so}
                    dropdownStyle={{ top: "30px" }}
                    labelText={""}
                    activityDropdownStyle={{ margin: "0 0 00px 0" }}
                    placeholder="Select Option"
                    title={
                      eventLimitValue
                        ? `${eventLimitValue} ${"Weeks"}`
                        : this.props.t("Select Weeks")
                    }
                    id="dropdown-recurring"
                    onClick={() => this.setState({ so: !this.state.so })}
                    data={eventLimits}
                    onSelect={this.onSelectCategory}
                    onSelectParameter={["eventLimitValue", "item"]}
                    active={eventLimitValue}
                    itemValue={false}
                    valueString={"Weeks"}
                    valueIndex={1}
                    selectedClassCategory={eventLimitValue}
                  />
                )}

                <StyledLabel color="#005c87">
                  {this.props.t("Select your email notifications for this event")}
                </StyledLabel>
                <Locationwrapper
                  border
                  background="none"
                  marginTop="0"
                  padding="0"
                >
                  {emailOptionKeys.map((item, index) => (
                    <div className="checkBoxWidth" key={index}>
                      <span style={{color:"#005c87"}}>
                        <CustomRadioButton
                          checked={emailOption[item] === 1}
                          onClick={() =>
                            !(
                              index === 0 &&
                              recurringEvent !== recurringTypes[0]
                            ) && this.onChangeEmail(item)
                          }
                          style={{border:"1px solid #649bb3"}}
                        >
                          {emailOption[item] === 1 && (
                            <img src={ImageUrl+"/images/newRadioCheck.svg"}></img>
                          )}
                        </CustomRadioButton>
                        {t("Event")}
                        {item == "launch_event_email"
                          ? t(" Creation")
                          : item == "event_reminder_email"? t(" Reminder")
                            : t(" Completion")}
                      </span>
                    </div>
                  ))}
                </Locationwrapper>
              </div>
            </div>
          </Container>
        </div>
      </Layout>
    );
  };

  render() {
    const {
      companiesPermission,
      locationPermission,
      step,
      city_location,
      companies,
      title,
      imgSrc,
      description,
      limitUser,
      location,
      eventLink,
      addLimitCheckbox,
      buttonStatus,
      selectedOption,
      iseventUrl,
      isEmployee,
      isDependent,
      isSpouse,
      usersToInvite,
      departments,
      teams,
      loadingOnDemandEvent,
      eventPointValue
    } = this.state;
    const { isLoading, allCompanies, userCompany, role, locationDetails, companyDetails } =
      this.props;
    if (
      (companiesPermission && !allCompanies) ||
      (locationPermission && !userCompany) ||
      isLoading || loadingOnDemandEvent
    ) {
      return <Waiting />;
    }
    return (
      <React.Fragment>
        {step === 1
          ? this.stepOneForm()
          : step === 2
            ? this.stepTwoForm()
            : step === 3
              ? this.stepThreeForm()
              : this.stepFourForm()}
        {step === 1 ? (
          <ButtonContainer>
            <Main>
              <CommonButton
                btnType={"squareIcon"}
                onClick={() => this.updateStep(2)}
                disabled={!title ||!imgSrc ||!description}
                title={this.props.t("Next")}
                styles={{color:"#007AB1"}}
                nextIcon={NextArrows("white")}
              />
            </Main>
          </ButtonContainer>
        ) : step === 2 ? (
          <ButtonContainer>
            <div className="wrapper">
              <CommonButton
                btnType={"squareIcon"}
                styles={{color:"white",textColor:"#007AB1",border:"#007AB1",width:"200px", marginLeft:"0px",display:"flex",justifyContent:"center",alignItems:"center" }}
                onClick={() => this.updateStep(1)}
                title={this.props.t("Go Back")}
                icon={Arrows()}
              />
              <CommonButton
                btnType={"squareIcon"}
                disabled={!limitUser||(addLimitCheckbox&&limitUser===0) ||(!location&&!eventLink) || (selectedOption === 1 && !iseventUrl) || (!eventPointValue && eventPointValue != 0) || (!this.state.selectFlayer && !this.state.flayerName)  }
                styles={{marginAuto:"auto",color:"#007AB1" }}
                onClick={() => this.updateStep(3)}
                title={this.props.t("Next")}
                nextIcon={NextArrows("white")}
              />
            </div>
          </ButtonContainer>
        ) : step === 3 ? (
          <ButtonContainer>
            <div className="wrapper">
              <CommonButton
                btnType={"squareIcon"}
                styles={{color:"white",textColor:"#007AB1",border:"#007AB1",width:"200px", marginLeft:"0px",display:"flex",justifyContent:"center",alignItems:"center" }}
                onClick={() => this.updateStep(2)}
                title={this.props.t("Go Back")}
                icon={Arrows()}
              />
              <CommonButton
                btnType={"squareIcon"}
                disabled={
                  ( !companies.length > 0 && role === "ADMIN") ||
                  (role !== "ADMIN" &&(!city_location.length > 0 &&!_.isEmpty(locationDetails) && usersToInvite === 2) || (role !== "ADMIN" && !isEmployee && !isDependent&& !isSpouse && _.isEmpty(locationDetails) && companyDetails && companyDetails['spouse_or_dependent']) || companies.length === 0 || (usersToInvite === 2 && !city_location.length > 0) || (usersToInvite === 1 && !departments.length > 0) || (usersToInvite === 3 && !teams.length > 0))
                }
                styles={{marginAuto:"auto",color:"#007AB1" }}
                onClick={() => this.updateStep(4)}
                title={this.props.t("Next")}
                nextIcon={NextArrows("white")}
              />
            </div>
          </ButtonContainer>
        ) : (
          step === 4 && (
            <ButtonContainer>
              <div className="wrapper">
                <CommonButton
                  btnType={"squareIcon"}
                  styles={{color:"white",textColor:"#007AB1",border:"#007AB1",width:"200px", marginLeft:"0px",display:"flex",justifyContent:"center",alignItems:"center"}}
                  onClick={() => this.nextStep(3)}
                  title={this.props.t("Go Back")}
                  icon={Arrows()}
                />
                <CommonButton
                  btnType={"squareIcon"}
                  disabled={buttonStatus || ( this.state.showFrequency && !this.state.endDate)}
                  styles={{color:"#007AB1", marginAuto:'auto'}}
                  onClick={!buttonStatus? () => this.saveEvent():null}
                  title={this.props.t("Launch Event")}
                  nextIcon={LaunchEventIcon()}
                />
              </div>
            </ButtonContainer>
          )
        )}
      </React.Fragment>
    );
  }
}

CreateLunchLearnEvent.propTypes = {
  createEvent: PropTypes.func.isRequired,
  getPlacesAction: PropTypes.func.isRequired,
  places: PropTypes.array,
  isLoading: PropTypes.bool,
  history: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
  fetchAllCompanies: PropTypes.func.isRequired,
  allCompanies: PropTypes.array,
  fetchCompanyForUser: PropTypes.func.isRequired,
  userCompany: PropTypes.object,
  locationDetails: PropTypes.array,
  allowChallengeEventForSpouseDependent: PropTypes.func,
  companyDetails: PropTypes.object,
  userPermissions: PropTypes.array,
  t: PropTypes.func,
  getCompanyLocation: PropTypes.func,
  getCompanyDepartment:PropTypes.func,
  departmentDetails:PropTypes.array,
};

const mapStateToProps = (state) => ({
  allCompanies: state.challenges.allCompanies,
  userCompany: state.profileData.userCompany,
  locationDetails: state.register.locationDetails,
  companyDetails: state.profileData.companyDetails,
  departmentDetails: state.register.departmentDetails,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAllCompanies: () => dispatch(getAllCompanies()),
  fetchCompanyForUser: () => dispatch(getUserCompanyDetails()),
  allowChallengeEventForSpouseDependent: () =>dispatch(allowChallengeEventForSpouseDependent),
  getCompanyLocation: (id) => dispatch(getCompanyLocation(id)),
  getCompanyDepartment: (id) => dispatch(getCompanyDepartment(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(CreateLunchLearnEvent)));
